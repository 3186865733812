import * as React from "react";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { RequirePermissions } from "shared/AlertMessages";
import withRouter from "services/withRouter";
import { GroupCode } from "shared/BarCode";
import funciones from "services/funciones";
import Loading from "shared/Loading";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Iconos from "images";
import log from "services/log";
import jsPDF from "jspdf";
import "svg2pdf.js";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const getDPI = () => {
    // Crea un elemento invisible para medir
    const dpiElement = document.createElement('div');
    dpiElement.style.width = '1in'; // 1 pulgada en CSS
    dpiElement.style.height = '1in'; // 1 pulgada en CSS
    dpiElement.style.position = 'absolute';
    dpiElement.style.left = '-100%'; // Posiciona fuera de la vista
    document.body.appendChild(dpiElement);

    // Obtiene el tamaño en píxeles
    const dpi = dpiElement.offsetWidth;

    // Elimina el elemento después de medir
    document.body.removeChild(dpiElement);

    return dpi;
}

const CodeGenerator = ({ navigate }) => {
    const { setTitulo, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [modalView, setModalView] = React.useState(null);
    const [cantidad, setCantidad] = React.useState("");
    const [codigos, setCodigos] = React.useState([]);
    const [generating, setGenerating] = React.useState(false);
    const [save, setSave] = React.useState(true);
    const input_cantidad = React.useRef();
    const codigosRef = React.useRef();
    const dpi = getDPI();
    const mmPerPixel = (25.4 / dpi).toFixed(6);
    const width = 1360.63; // Ancho en px
    const height = 935.43; // Alto en px
    // const pdfWidth = 247.54; // Ancho en mm  43.6 - 47.6 
    const pdfWidth = width * mmPerPixel; // Ancho en mm
    // const pdfHeight = 345.47; // Alto en mm  30.6 - 32.8
    const pdfHeight = height * mmPerPixel; // Alto en mm


    const handleDownload = async () => {
        log.d("Generando PDF...");

        const pdf = new jsPDF("p", "mm", [pdfWidth, pdfHeight]);
        const svgElements = codigosRef.current.querySelectorAll("svg");

        for (let index = 0; index < svgElements.length; index++) {
            const svg = svgElements[index];

            log.d("Agregando SVG " + (index + 1), svg);
            document.getElementById("contador").innerHTML = index + 1 + "/" + cantidad;

            await pdf.svg(svg, {
                x: 0,
                y: 0,
                width: pdfHeight,
                height: pdfWidth
            });

            if (index < svgElements.length - 1) pdf.addPage();
        }
        pdf.save("codigos_" + codigos[0][0].aux + codigos[0][0].code + "_" + codigos[codigos.length - 1][143].aux + codigos[codigos.length - 1][143].code + ".pdf");
        setGenerating(false);
        setSave(true);

        log.d("Códigos generados ", codigos[0][0].aux + codigos[0][0].code + " a " + codigos[codigos.length - 1][143].aux + codigos[codigos.length - 1][143].code);
    }

    const handleCntidad = (e) => {
        e.preventDefault();

        alertaRemove(input_cantidad.current);

        if (isNaN(parseInt(cantidad))) {
            alerta(input_cantidad.current, "Ingresa una cantidad valida");
            input_cantidad.current.focus();
            return;
        }

        if (parseInt(cantidad) < 1) {
            alerta(input_cantidad.current, "La cantidad debe ser mayor a 0");
            input_cantidad.current.focus();
            return;
        }

        getListCodes();
    }

    const getListCodes = async () => {
        setModalView(<Loading />);
        setCodigos([]);

        let _pages = [];
        let _data = await api.current.fetchJson({
            url: "genera-codigos",
            data: {
                cantidad: 144 * parseInt(cantidad),
            }
        });

        if (_data.response == 1) {
            let _first_code = parseInt(_data.data.first_code);

            for (let p = 1; p <= parseInt(cantidad); p++) {
                let _codes = [];

                for (let c = _first_code; c < (_first_code + 144); c++) {
                    _codes.push({ code: c, aux: _data.data.aux, estado: 0 });
                }

                _first_code = (_first_code + 144);
                _pages.push(_codes);
            }

            setSave(false);
            setCodigos(_pages);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setModalView(null);
    }

    React.useEffect(() => {
        if (generating) {
            setTimeout(() => {
                handleDownload();
            }, 1000);
        }
    }, [generating]);

    React.useEffect(() => {
        setTitulo("Generador de códigos");
        document.body.style.overflow = "auto";

        const handleBeforePrint = () => {
            log.w("Se activó la impresión");
            log.w(`mmPerPixel: ${mmPerPixel} dpi: ${dpi}`);
            log.w(`size: ${height}px ${width}px`);
            log.w(`size: ${(height * mmPerPixel).toFixed(2)}mm ${(width * mmPerPixel).toFixed(2)}mm`); 
            const printStyles = `
                @page {
                    size: ${height}px ${width + 12}px;
                }

                @media print {
                    body {
                        width: ${height}px;
                        height: ${width + 12}px;
                        padding: 0;
                        margin: 0;
                        background-color: rgb(255, 255, 255) !important;
                        color: rgb(20, 20, 20);
                    }

                    header,
                    footer,
                    .layout,
                    .options_output,
                    .main_container .options_generator {
                        display: none !important;
                    }

                    .main_container,
                    .container__wrap {
                        padding: 0;
                        margin: 0 auto;
                        background-color: rgb(255, 255, 255) !important;
                    }
                }
            `;

            if (!document.getElementById("printCodeStyles")) {
                const styleSheet = document.createElement("style");
                styleSheet.id = "printCodeStyles";
                styleSheet.innerHTML = printStyles;
                document.head.appendChild(styleSheet);
                log.d("Se agrego el estilo de impresión");
            } else {
                log.d("El estilo de impresión ya existe");
            }

            setSave(true);
        };

        window.addEventListener('beforeprint', handleBeforePrint);

        return () => window.removeEventListener('beforeprint', handleBeforePrint);
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("genera_codigos")}>

            <div className="main_container">

                <div className="options_generator">
                    <p className="_alerta">Ten en cuenta que los códigos generados son únicos y no se repetirán, si no guardas el documento los códigos generados se perderán.</p>
                    <label>Cantidad de páginas</label>

                    <div className="input_generator">
                        <input type="number" name="cantidad" ref={input_cantidad} placeholder="0"
                            value={cantidad} onChange={e => { alertaRemove(input_cantidad.current); setCantidad(e.target.value); }} />
                    </div>
                    <p className="_alerta" style={{ fontWeight: "normal", margin: "0 0 24px" }}>Si vas a generar una gran cantidad de páginas es aconsejable que las dividas en grupos de máximo diez para evitar que la memoria se sobrecargue y genere errores inesperados.</p>

                    {save && <button className="btn btn-common" onClick={handleCntidad}>Generar Códigos</button>}

                    {codigos.length > 0 && <h3>Códigos desde {codigos[0][0].aux + codigos[0][0].code} hasta el {codigos[codigos.length - 1][143].aux + codigos[codigos.length - 1][143].code}</h3>}
                </div>

                {codigos.length > 0 && <div className="content_generator">
                    <div className="code_generator" ref={codigosRef}>

                        {codigos.map((item, index) => {
                            return (
                                <svg key={index} version="1.2" width="935.43311" height="1360.62988" viewBox="0 0 701.574829 1020.472412" xmlns="http://www.w3.org/2000/svg">
                                    <text style={{ fontWeight: "bold", fontSize: "1.3rem", fontFamily: "'Roboto', sans-serif" }} textAnchor="middle" x="350" y="44" transform="matrix(0, -1, 1, 0, -15, 824)" fill="rgb(107 107 107)">Códigos desde {item[0].aux}{item[0].code} hasta el {item[item.length - 1].aux}{item[item.length - 1].code}</text>
                                    <g>
                                        <path
                                            fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                            d="M 85.036458 56.692708 L 85.036458 58.114583 L 28.348958 58.114583 L 28.348958 56.692708 Z M 85.036458 56.692708 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                        <path
                                            fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                            d="M 28.348958 56.692708 L 29.765625 56.692708 L 29.765625 113.385417 L 28.348958 113.385417 Z M 28.348958 56.692708 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                        <path
                                            fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                            d="M 907.088542 56.692708 L 907.088542 58.114583 L 850.395833 58.114583 L 850.395833 56.692708 Z M 907.088542 56.692708 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                        <path
                                            fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                            d="M 905.666667 56.692708 L 907.088542 56.692708 L 907.088542 113.385417 L 905.666667 113.385417 Z M 905.666667 56.692708 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                        <path
                                            fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                            d="M 28.348958 1302.515625 L 85.036458 1302.515625 L 85.036458 1303.9375 L 28.348958 1303.9375 Z M 28.348958 1302.515625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                        <path
                                            fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                            d="M 28.348958 1247.244792 L 29.765625 1247.244792 L 29.765625 1303.9375 L 28.348958 1303.9375 Z M 28.348958 1247.244792 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                        <path
                                            fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                            d="M 850.395833 1302.515625 L 907.088542 1302.515625 L 907.088542 1303.9375 L 850.395833 1303.9375 Z M 850.395833 1302.515625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                        <path
                                            fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                            d="M 905.666667 1247.244792 L 907.088542 1247.244792 L 907.088542 1303.9375 L 905.666667 1303.9375 Z M 905.666667 1247.244792 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </g>
                                    <g>
                                        <GroupCode code={funciones.formatCeros(item[0])}
                                            logo="matrix(0, -0.01, -0.01, 0, 67, 115)"
                                            bars="matrix(0, -0.36, 0.36, 0, 70, 132)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 71.645833 186.3125 L 137.645833 186.3125 L 137.645833 88.0625 L 71.645833 88.0625 Z M 71.645833 186.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[1])}
                                            logo="matrix(0, -0.01, -0.01, 0, 117, 115)"
                                            bars="matrix(0, -0.36, 0.36, 0, 120, 132)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 137.645833 186.3125 L 203.645833 186.3125 L 203.645833 88.0625 L 137.645833 88.0625 Z M 137.645833 186.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[2])}
                                            logo="matrix(0, -0.01, -0.01, 0, 167, 115)"
                                            bars="matrix(0, -0.36, 0.36, 0, 170, 132)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 203.645833 186.3125 L 269.645833 186.3125 L 269.645833 88.0625 L 203.645833 88.0625 Z M 203.645833 186.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[3])}
                                            logo="matrix(0, -0.01, -0.01, 0, 217, 115)"
                                            bars="matrix(0, -0.36, 0.36, 0, 220, 132)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 269.645833 186.3125 L 335.645833 186.3125 L 335.645833 88.0625 L 269.645833 88.0625 Z M 269.645833 186.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[4])}
                                            logo="matrix(0, -0.01, -0.01, 0, 267, 115)"
                                            bars="matrix(0, -0.36, 0.36, 0, 270, 132)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 335.645833 186.3125 L 401.645833 186.3125 L 401.645833 88.0625 L 335.645833 88.0625 Z M 335.645833 186.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[5])}
                                            logo="matrix(0, -0.01, -0.01, 0, 317, 115)"
                                            bars="matrix(0, -0.36, 0.36, 0, 320, 132)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 401.645833 186.3125 L 467.645833 186.3125 L 467.645833 88.0625 L 401.645833 88.0625 Z M 401.645833 186.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[6])}
                                            logo="matrix(0, -0.01, -0.01, 0, 367, 115)"
                                            bars="matrix(0, -0.36, 0.36, 0, 370, 132)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 467.645833 186.3125 L 533.645833 186.3125 L 533.645833 88.0625 L 467.645833 88.0625 Z M 467.645833 186.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[7])}
                                            logo="matrix(0, -0.01, -0.01, 0, 417, 115)"
                                            bars="matrix(0, -0.36, 0.36, 0, 420, 132)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 533.645833 186.3125 L 599.645833 186.3125 L 599.645833 88.0625 L 533.645833 88.0625 Z M 533.645833 186.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[8])}
                                            logo="matrix(0, -0.01, -0.01, 0, 467, 115)"
                                            bars="matrix(0, -0.36, 0.36, 0, 470, 132)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 599.645833 186.3125 L 665.645833 186.3125 L 665.645833 88.0625 L 599.645833 88.0625 Z M 599.645833 186.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[9])}
                                            logo="matrix(0, -0.01, -0.01, 0, 517, 115)"
                                            bars="matrix(0, -0.36, 0.36, 0, 520, 132)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 665.645833 186.3125 L 731.645833 186.3125 L 731.645833 88.0625 L 665.645833 88.0625 Z M 665.645833 186.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[10])}
                                            logo="matrix(0, -0.01, -0.01, 0, 567, 115)"
                                            bars="matrix(0, -0.36, 0.36, 0, 570, 132)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 731.645833 186.3125 L 797.645833 186.3125 L 797.645833 88.0625 L 731.645833 88.0625 Z M 731.645833 186.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[11])}
                                            logo="matrix(0, -0.01, -0.01, 0, 617, 115)"
                                            bars="matrix(0, -0.36, 0.36, 0, 620, 132)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 797.645833 186.3125 L 863.645833 186.3125 L 863.645833 88.0625 L 797.645833 88.0625 Z M 797.645833 186.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[12])}
                                            logo="matrix(0, -0.01, -0.01, 0, 67, 190)"
                                            bars="matrix(0, -0.36, 0.36, 0, 70, 207)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 71.645833 284.5625 L 137.645833 284.5625 L 137.645833 186.3125 L 71.645833 186.3125 Z M 71.645833 284.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[13])}
                                            logo="matrix(0, -0.01, -0.01, 0, 117, 190)"
                                            bars="matrix(0, -0.36, 0.36, 0, 120, 207)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 137.645833 284.5625 L 203.645833 284.5625 L 203.645833 186.3125 L 137.645833 186.3125 Z M 137.645833 284.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[14])}
                                            logo="matrix(0, -0.01, -0.01, 0, 167, 190)"
                                            bars="matrix(0, -0.36, 0.36, 0, 170, 207)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 203.645833 284.5625 L 269.645833 284.5625 L 269.645833 186.3125 L 203.645833 186.3125 Z M 203.645833 284.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[15])}
                                            logo="matrix(0, -0.01, -0.01, 0, 217, 190)"
                                            bars="matrix(0, -0.36, 0.36, 0, 220, 207)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 269.645833 284.5625 L 335.645833 284.5625 L 335.645833 186.3125 L 269.645833 186.3125 Z M 269.645833 284.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[16])}
                                            logo="matrix(0, -0.01, -0.01, 0, 267, 190)"
                                            bars="matrix(0, -0.36, 0.36, 0, 270, 207)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 335.645833 284.5625 L 401.645833 284.5625 L 401.645833 186.3125 L 335.645833 186.3125 Z M 335.645833 284.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[17])}
                                            logo="matrix(0, -0.01, -0.01, 0, 317, 190)"
                                            bars="matrix(0, -0.36, 0.36, 0, 320, 207)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 401.645833 284.5625 L 467.645833 284.5625 L 467.645833 186.3125 L 401.645833 186.3125 Z M 401.645833 284.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[18])}
                                            logo="matrix(0, -0.01, -0.01, 0, 367, 190)"
                                            bars="matrix(0, -0.36, 0.36, 0, 370, 207)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 467.645833 284.5625 L 533.645833 284.5625 L 533.645833 186.3125 L 467.645833 186.3125 Z M 467.645833 284.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[19])}
                                            logo="matrix(0, -0.01, -0.01, 0, 417, 190)"
                                            bars="matrix(0, -0.36, 0.36, 0, 420, 207)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 533.645833 284.5625 L 599.645833 284.5625 L 599.645833 186.3125 L 533.645833 186.3125 Z M 533.645833 284.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[20])}
                                            logo="matrix(0, -0.01, -0.01, 0, 467, 190)"
                                            bars="matrix(0, -0.36, 0.36, 0, 470, 207)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 599.645833 284.5625 L 665.645833 284.5625 L 665.645833 186.3125 L 599.645833 186.3125 Z M 599.645833 284.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[21])}
                                            logo="matrix(0, -0.01, -0.01, 0, 517, 190)"
                                            bars="matrix(0, -0.36, 0.36, 0, 520, 207)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 665.645833 284.5625 L 731.645833 284.5625 L 731.645833 186.3125 L 665.645833 186.3125 Z M 665.645833 284.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[22])}
                                            logo="matrix(0, -0.01, -0.01, 0, 567, 190)"
                                            bars="matrix(0, -0.36, 0.36, 0, 570, 207)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 731.645833 284.5625 L 797.645833 284.5625 L 797.645833 186.3125 L 731.645833 186.3125 Z M 731.645833 284.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[23])}
                                            logo="matrix(0, -0.01, -0.01, 0, 617, 190)"
                                            bars="matrix(0, -0.36, 0.36, 0, 620, 207)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 797.645833 284.5625 L 863.645833 284.5625 L 863.645833 186.3125 L 797.645833 186.3125 Z M 797.645833 284.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[24])}
                                            logo="matrix(0, -0.01, -0.01, 0, 67, 263)"
                                            bars="matrix(0, -0.36, 0.36, 0, 70, 280)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 71.645833 382.8125 L 137.645833 382.8125 L 137.645833 284.5625 L 71.645833 284.5625 Z M 71.645833 382.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[25])}
                                            logo="matrix(0, -0.01, -0.01, 0, 117, 263)"
                                            bars="matrix(0, -0.36, 0.36, 0, 120, 280)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 137.645833 382.8125 L 203.645833 382.8125 L 203.645833 284.5625 L 137.645833 284.5625 Z M 137.645833 382.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[26])}
                                            logo="matrix(0, -0.01, -0.01, 0, 167, 263)"
                                            bars="matrix(0, -0.36, 0.36, 0, 170, 280)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 203.645833 382.8125 L 269.645833 382.8125 L 269.645833 284.5625 L 203.645833 284.5625 Z M 203.645833 382.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[27])}
                                            logo="matrix(0, -0.01, -0.01, 0, 217, 263)"
                                            bars="matrix(0, -0.36, 0.36, 0, 220, 280)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 269.645833 382.8125 L 335.645833 382.8125 L 335.645833 284.5625 L 269.645833 284.5625 Z M 269.645833 382.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[28])}
                                            logo="matrix(0, -0.01, -0.01, 0, 267, 263)"
                                            bars="matrix(0, -0.36, 0.36, 0, 270, 280)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 335.645833 382.8125 L 401.645833 382.8125 L 401.645833 284.5625 L 335.645833 284.5625 Z M 335.645833 382.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[29])}
                                            logo="matrix(0, -0.01, -0.01, 0, 317, 263)"
                                            bars="matrix(0, -0.36, 0.36, 0, 320, 280)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 401.645833 382.8125 L 467.645833 382.8125 L 467.645833 284.5625 L 401.645833 284.5625 Z M 401.645833 382.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[30])}
                                            logo="matrix(0, -0.01, -0.01, 0, 367, 263)"
                                            bars="matrix(0, -0.36, 0.36, 0, 370, 280)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 467.645833 382.8125 L 533.645833 382.8125 L 533.645833 284.5625 L 467.645833 284.5625 Z M 467.645833 382.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[31])}
                                            logo="matrix(0, -0.01, -0.01, 0, 417, 263)"
                                            bars="matrix(0, -0.36, 0.36, 0, 420, 280)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 533.645833 382.8125 L 599.645833 382.8125 L 599.645833 284.5625 L 533.645833 284.5625 Z M 533.645833 382.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[32])}
                                            logo="matrix(0, -0.01, -0.01, 0, 467, 263)"
                                            bars="matrix(0, -0.36, 0.36, 0, 470, 280)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 599.645833 382.8125 L 665.645833 382.8125 L 665.645833 284.5625 L 599.645833 284.5625 Z M 599.645833 382.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[33])}
                                            logo="matrix(0, -0.01, -0.01, 0, 517, 263)"
                                            bars="matrix(0, -0.36, 0.36, 0, 520, 280)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 665.645833 382.8125 L 731.645833 382.8125 L 731.645833 284.5625 L 665.645833 284.5625 Z M 665.645833 382.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[34])}
                                            logo="matrix(0, -0.01, -0.01, 0, 567, 263)"
                                            bars="matrix(0, -0.36, 0.36, 0, 570, 280)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 731.645833 382.8125 L 797.645833 382.8125 L 797.645833 284.5625 L 731.645833 284.5625 Z M 731.645833 382.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[35])}
                                            logo="matrix(0, -0.01, -0.01, 0, 617, 263)"
                                            bars="matrix(0, -0.36, 0.36, 0, 620, 280)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 797.645833 382.8125 L 863.645833 382.8125 L 863.645833 284.5625 L 797.645833 284.5625 Z M 797.645833 382.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[36])}
                                            logo="matrix(0, -0.01, -0.01, 0, 67, 336)"
                                            bars="matrix(0, -0.36, 0.36, 0, 70, 353)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 71.645833 481.0625 L 137.645833 481.0625 L 137.645833 382.8125 L 71.645833 382.8125 Z M 71.645833 481.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[37])}
                                            logo="matrix(0, -0.01, -0.01, 0, 117, 336)"
                                            bars="matrix(0, -0.36, 0.36, 0, 120, 353)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 137.645833 481.0625 L 203.645833 481.0625 L 203.645833 382.8125 L 137.645833 382.8125 Z M 137.645833 481.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[38])}
                                            logo="matrix(0, -0.01, -0.01, 0, 167, 336)"
                                            bars="matrix(0, -0.36, 0.36, 0, 170, 353)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 203.645833 481.0625 L 269.645833 481.0625 L 269.645833 382.8125 L 203.645833 382.8125 Z M 203.645833 481.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[39])}
                                            logo="matrix(0, -0.01, -0.01, 0, 217, 336)"
                                            bars="matrix(0, -0.36, 0.36, 0, 220, 353)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 269.645833 481.0625 L 335.645833 481.0625 L 335.645833 382.8125 L 269.645833 382.8125 Z M 269.645833 481.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[40])}
                                            logo="matrix(0, -0.01, -0.01, 0, 267, 336)"
                                            bars="matrix(0, -0.36, 0.36, 0, 270, 353)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 335.645833 481.0625 L 401.645833 481.0625 L 401.645833 382.8125 L 335.645833 382.8125 Z M 335.645833 481.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[41])}
                                            logo="matrix(0, -0.01, -0.01, 0, 317, 336)"
                                            bars="matrix(0, -0.36, 0.36, 0, 320, 353)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 401.645833 481.0625 L 467.645833 481.0625 L 467.645833 382.8125 L 401.645833 382.8125 Z M 401.645833 481.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[42])}
                                            logo="matrix(0, -0.01, -0.01, 0, 367, 336)"
                                            bars="matrix(0, -0.36, 0.36, 0, 370, 353)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 467.645833 481.0625 L 533.645833 481.0625 L 533.645833 382.8125 L 467.645833 382.8125 Z M 467.645833 481.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[43])}
                                            logo="matrix(0, -0.01, -0.01, 0, 417, 336)"
                                            bars="matrix(0, -0.36, 0.36, 0, 420, 353)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 533.645833 481.0625 L 599.645833 481.0625 L 599.645833 382.8125 L 533.645833 382.8125 Z M 533.645833 481.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[44])}
                                            logo="matrix(0, -0.01, -0.01, 0, 467, 336)"
                                            bars="matrix(0, -0.36, 0.36, 0, 470, 353)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 599.645833 481.0625 L 665.645833 481.0625 L 665.645833 382.8125 L 599.645833 382.8125 Z M 599.645833 481.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[45])}
                                            logo="matrix(0, -0.01, -0.01, 0, 517, 336)"
                                            bars="matrix(0, -0.36, 0.36, 0, 520, 353)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 665.645833 481.0625 L 731.645833 481.0625 L 731.645833 382.8125 L 665.645833 382.8125 Z M 665.645833 481.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[46])}
                                            logo="matrix(0, -0.01, -0.01, 0, 567, 336)"
                                            bars="matrix(0, -0.36, 0.36, 0, 570, 353)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 731.645833 481.0625 L 797.645833 481.0625 L 797.645833 382.8125 L 731.645833 382.8125 Z M 731.645833 481.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[47])}
                                            logo="matrix(0, -0.01, -0.01, 0, 617, 336)"
                                            bars="matrix(0, -0.36, 0.36, 0, 620, 353)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 797.645833 481.0625 L 863.645833 481.0625 L 863.645833 382.8125 L 797.645833 382.8125 Z M 797.645833 481.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[48])}
                                            logo="matrix(0, -0.01, -0.01, 0, 67, 411)"
                                            bars="matrix(0, -0.36, 0.36, 0, 70, 428)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 71.645833 579.3125 L 137.645833 579.3125 L 137.645833 481.0625 L 71.645833 481.0625 Z M 71.645833 579.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[49])}
                                            logo="matrix(0, -0.01, -0.01, 0, 117, 411)"
                                            bars="matrix(0, -0.36, 0.36, 0, 120, 428)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 137.645833 579.3125 L 203.645833 579.3125 L 203.645833 481.0625 L 137.645833 481.0625 Z M 137.645833 579.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[50])}
                                            logo="matrix(0, -0.01, -0.01, 0, 167, 411)"
                                            bars="matrix(0, -0.36, 0.36, 0, 170, 428)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 203.645833 579.3125 L 269.645833 579.3125 L 269.645833 481.0625 L 203.645833 481.0625 Z M 203.645833 579.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[51])}
                                            logo="matrix(0, -0.01, -0.01, 0, 217, 411)"
                                            bars="matrix(0, -0.36, 0.36, 0, 220, 428)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 269.645833 579.3125 L 335.645833 579.3125 L 335.645833 481.0625 L 269.645833 481.0625 Z M 269.645833 579.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[52])}
                                            logo="matrix(0, -0.01, -0.01, 0, 267, 411)"
                                            bars="matrix(0, -0.36, 0.36, 0, 270, 428)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 335.645833 579.3125 L 401.645833 579.3125 L 401.645833 481.0625 L 335.645833 481.0625 Z M 335.645833 579.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[53])}
                                            logo="matrix(0, -0.01, -0.01, 0, 317, 411)"
                                            bars="matrix(0, -0.36, 0.36, 0, 320, 428)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 401.645833 579.3125 L 467.645833 579.3125 L 467.645833 481.0625 L 401.645833 481.0625 Z M 401.645833 579.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[54])}
                                            logo="matrix(0, -0.01, -0.01, 0, 367, 411)"
                                            bars="matrix(0, -0.36, 0.36, 0, 370, 428)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 467.645833 579.3125 L 533.645833 579.3125 L 533.645833 481.0625 L 467.645833 481.0625 Z M 467.645833 579.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[55])}
                                            logo="matrix(0, -0.01, -0.01, 0, 417, 411)"
                                            bars="matrix(0, -0.36, 0.36, 0, 420, 428)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 533.645833 579.3125 L 599.645833 579.3125 L 599.645833 481.0625 L 533.645833 481.0625 Z M 533.645833 579.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[56])}
                                            logo="matrix(0, -0.01, -0.01, 0, 467, 411)"
                                            bars="matrix(0, -0.36, 0.36, 0, 470, 428)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 599.645833 579.3125 L 665.645833 579.3125 L 665.645833 481.0625 L 599.645833 481.0625 Z M 599.645833 579.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[57])}
                                            logo="matrix(0, -0.01, -0.01, 0, 517, 411)"
                                            bars="matrix(0, -0.36, 0.36, 0, 520, 428)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 665.645833 579.3125 L 731.645833 579.3125 L 731.645833 481.0625 L 665.645833 481.0625 Z M 665.645833 579.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[58])}
                                            logo="matrix(0, -0.01, -0.01, 0, 567, 411)"
                                            bars="matrix(0, -0.36, 0.36, 0, 570, 428)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 731.645833 579.3125 L 797.645833 579.3125 L 797.645833 481.0625 L 731.645833 481.0625 Z M 731.645833 579.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[59])}
                                            logo="matrix(0, -0.01, -0.01, 0, 617, 411)"
                                            bars="matrix(0, -0.36, 0.36, 0, 620, 428)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 797.645833 579.3125 L 863.645833 579.3125 L 863.645833 481.0625 L 797.645833 481.0625 Z M 797.645833 579.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[60])}
                                            logo="matrix(0, -0.01, -0.01, 0, 67, 484)"
                                            bars="matrix(0, -0.36, 0.36, 0, 70, 501)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 71.645833 677.5625 L 137.645833 677.5625 L 137.645833 579.3125 L 71.645833 579.3125 Z M 71.645833 677.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[61])}
                                            logo="matrix(0, -0.01, -0.01, 0, 117, 484)"
                                            bars="matrix(0, -0.36, 0.36, 0, 120, 501)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 137.645833 677.5625 L 203.645833 677.5625 L 203.645833 579.3125 L 137.645833 579.3125 Z M 137.645833 677.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[62])}
                                            logo="matrix(0, -0.01, -0.01, 0, 167, 484)"
                                            bars="matrix(0, -0.36, 0.36, 0, 170, 501)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 203.645833 677.5625 L 269.645833 677.5625 L 269.645833 579.3125 L 203.645833 579.3125 Z M 203.645833 677.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[63])}
                                            logo="matrix(0, -0.01, -0.01, 0, 217, 484)"
                                            bars="matrix(0, -0.36, 0.36, 0, 220, 501)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 269.645833 677.5625 L 335.645833 677.5625 L 335.645833 579.3125 L 269.645833 579.3125 Z M 269.645833 677.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[64])}
                                            logo="matrix(0, -0.01, -0.01, 0, 267, 484)"
                                            bars="matrix(0, -0.36, 0.36, 0, 270, 501)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 335.645833 677.5625 L 401.645833 677.5625 L 401.645833 579.3125 L 335.645833 579.3125 Z M 335.645833 677.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[65])}
                                            logo="matrix(0, -0.01, -0.01, 0, 317, 484)"
                                            bars="matrix(0, -0.36, 0.36, 0, 320, 501)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 401.645833 677.5625 L 467.645833 677.5625 L 467.645833 579.3125 L 401.645833 579.3125 Z M 401.645833 677.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[66])}
                                            logo="matrix(0, -0.01, -0.01, 0, 367, 484)"
                                            bars="matrix(0, -0.36, 0.36, 0, 370, 501)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 467.645833 677.5625 L 533.645833 677.5625 L 533.645833 579.3125 L 467.645833 579.3125 Z M 467.645833 677.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[67])}
                                            logo="matrix(0, -0.01, -0.01, 0, 417, 484)"
                                            bars="matrix(0, -0.36, 0.36, 0, 420, 501)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 533.645833 677.5625 L 599.645833 677.5625 L 599.645833 579.3125 L 533.645833 579.3125 Z M 533.645833 677.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[68])}
                                            logo="matrix(0, -0.01, -0.01, 0, 467, 484)"
                                            bars="matrix(0, -0.36, 0.36, 0, 470, 501)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 599.645833 677.5625 L 665.645833 677.5625 L 665.645833 579.3125 L 599.645833 579.3125 Z M 599.645833 677.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[69])}
                                            logo="matrix(0, -0.01, -0.01, 0, 517, 484)"
                                            bars="matrix(0, -0.36, 0.36, 0, 520, 501)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 665.645833 677.5625 L 731.645833 677.5625 L 731.645833 579.3125 L 665.645833 579.3125 Z M 665.645833 677.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[70])}
                                            logo="matrix(0, -0.01, -0.01, 0, 567, 484)"
                                            bars="matrix(0, -0.36, 0.36, 0, 570, 501)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 731.645833 677.5625 L 797.645833 677.5625 L 797.645833 579.3125 L 731.645833 579.3125 Z M 731.645833 677.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[71])}
                                            logo="matrix(0, -0.01, -0.01, 0, 617, 484)"
                                            bars="matrix(0, -0.36, 0.36, 0, 620, 501)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 797.645833 677.5625 L 863.645833 677.5625 L 863.645833 579.3125 L 797.645833 579.3125 Z M 797.645833 677.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[72])}
                                            logo="matrix(0, -0.01, -0.01, 0, 67, 559)"
                                            bars="matrix(0, -0.36, 0.36, 0, 70, 576)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 71.645833 775.8125 L 137.645833 775.8125 L 137.645833 677.5625 L 71.645833 677.5625 Z M 71.645833 775.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[73])}
                                            logo="matrix(0, -0.01, -0.01, 0, 117, 559)"
                                            bars="matrix(0, -0.36, 0.36, 0, 120, 576)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 137.645833 775.8125 L 203.645833 775.8125 L 203.645833 677.5625 L 137.645833 677.5625 Z M 137.645833 775.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[74])}
                                            logo="matrix(0, -0.01, -0.01, 0, 167, 559)"
                                            bars="matrix(0, -0.36, 0.36, 0, 170, 576)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 203.645833 775.8125 L 269.645833 775.8125 L 269.645833 677.5625 L 203.645833 677.5625 Z M 203.645833 775.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[75])}
                                            logo="matrix(0, -0.01, -0.01, 0, 217, 559)"
                                            bars="matrix(0, -0.36, 0.36, 0, 220, 576)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 269.645833 775.8125 L 335.645833 775.8125 L 335.645833 677.5625 L 269.645833 677.5625 Z M 269.645833 775.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[76])}
                                            logo="matrix(0, -0.01, -0.01, 0, 267, 559)"
                                            bars="matrix(0, -0.36, 0.36, 0, 270, 576)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 335.645833 775.8125 L 401.645833 775.8125 L 401.645833 677.5625 L 335.645833 677.5625 Z M 335.645833 775.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[77])}
                                            logo="matrix(0, -0.01, -0.01, 0, 317, 559)"
                                            bars="matrix(0, -0.36, 0.36, 0, 320, 576)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 401.645833 775.8125 L 467.645833 775.8125 L 467.645833 677.5625 L 401.645833 677.5625 Z M 401.645833 775.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[78])}
                                            logo="matrix(0, -0.01, -0.01, 0, 367, 559)"
                                            bars="matrix(0, -0.36, 0.36, 0, 370, 576)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 467.645833 775.8125 L 533.645833 775.8125 L 533.645833 677.5625 L 467.645833 677.5625 Z M 467.645833 775.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[79])}
                                            logo="matrix(0, -0.01, -0.01, 0, 417, 559)"
                                            bars="matrix(0, -0.36, 0.36, 0, 420, 576)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 533.645833 775.8125 L 599.645833 775.8125 L 599.645833 677.5625 L 533.645833 677.5625 Z M 533.645833 775.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[80])}
                                            logo="matrix(0, -0.01, -0.01, 0, 467, 559)"
                                            bars="matrix(0, -0.36, 0.36, 0, 470, 576)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 599.645833 775.8125 L 665.645833 775.8125 L 665.645833 677.5625 L 599.645833 677.5625 Z M 599.645833 775.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[81])}
                                            logo="matrix(0, -0.01, -0.01, 0, 517, 559)"
                                            bars="matrix(0, -0.36, 0.36, 0, 520, 576)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 665.645833 775.8125 L 731.645833 775.8125 L 731.645833 677.5625 L 665.645833 677.5625 Z M 665.645833 775.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[82])}
                                            logo="matrix(0, -0.01, -0.01, 0, 567, 559)"
                                            bars="matrix(0, -0.36, 0.36, 0, 570, 576)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 731.645833 775.8125 L 797.645833 775.8125 L 797.645833 677.5625 L 731.645833 677.5625 Z M 731.645833 775.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[83])}
                                            logo="matrix(0, -0.01, -0.01, 0, 617, 559)"
                                            bars="matrix(0, -0.36, 0.36, 0, 620, 576)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 797.645833 775.8125 L 863.645833 775.8125 L 863.645833 677.5625 L 797.645833 677.5625 Z M 797.645833 775.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[84])}
                                            logo="matrix(0, -0.01, -0.01, 0, 67, 632)"
                                            bars="matrix(0, -0.36, 0.36, 0, 70, 649)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 71.645833 874.0625 L 137.645833 874.0625 L 137.645833 775.8125 L 71.645833 775.8125 Z M 71.645833 874.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[85])}
                                            logo="matrix(0, -0.01, -0.01, 0, 117, 632)"
                                            bars="matrix(0, -0.36, 0.36, 0, 120, 649)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 137.645833 874.0625 L 203.645833 874.0625 L 203.645833 775.8125 L 137.645833 775.8125 Z M 137.645833 874.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[86])}
                                            logo="matrix(0, -0.01, -0.01, 0, 167, 632)"
                                            bars="matrix(0, -0.36, 0.36, 0, 170, 649)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 203.645833 874.0625 L 269.645833 874.0625 L 269.645833 775.8125 L 203.645833 775.8125 Z M 203.645833 874.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[87])}
                                            logo="matrix(0, -0.01, -0.01, 0, 217, 632)"
                                            bars="matrix(0, -0.36, 0.36, 0, 220, 649)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 269.645833 874.0625 L 335.645833 874.0625 L 335.645833 775.8125 L 269.645833 775.8125 Z M 269.645833 874.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[88])}
                                            logo="matrix(0, -0.01, -0.01, 0, 267, 632)"
                                            bars="matrix(0, -0.36, 0.36, 0, 270, 649)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 335.645833 874.0625 L 401.645833 874.0625 L 401.645833 775.8125 L 335.645833 775.8125 Z M 335.645833 874.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[89])}
                                            logo="matrix(0, -0.01, -0.01, 0, 317, 632)"
                                            bars="matrix(0, -0.36, 0.36, 0, 320, 649)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 401.645833 874.0625 L 467.645833 874.0625 L 467.645833 775.8125 L 401.645833 775.8125 Z M 401.645833 874.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[90])}
                                            logo="matrix(0, -0.01, -0.01, 0, 367, 632)"
                                            bars="matrix(0, -0.36, 0.36, 0, 370, 649)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 467.645833 874.0625 L 533.645833 874.0625 L 533.645833 775.8125 L 467.645833 775.8125 Z M 467.645833 874.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[91])}
                                            logo="matrix(0, -0.01, -0.01, 0, 417, 632)"
                                            bars="matrix(0, -0.36, 0.36, 0, 420, 649)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 533.645833 874.0625 L 599.645833 874.0625 L 599.645833 775.8125 L 533.645833 775.8125 Z M 533.645833 874.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[92])}
                                            logo="matrix(0, -0.01, -0.01, 0, 467, 632)"
                                            bars="matrix(0, -0.36, 0.36, 0, 470, 649)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 599.645833 874.0625 L 665.645833 874.0625 L 665.645833 775.8125 L 599.645833 775.8125 Z M 599.645833 874.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[93])}
                                            logo="matrix(0, -0.01, -0.01, 0, 517, 632)"
                                            bars="matrix(0, -0.36, 0.36, 0, 520, 649)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 665.645833 874.0625 L 731.645833 874.0625 L 731.645833 775.8125 L 665.645833 775.8125 Z M 665.645833 874.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[94])}
                                            logo="matrix(0, -0.01, -0.01, 0, 567, 632)"
                                            bars="matrix(0, -0.36, 0.36, 0, 570, 649)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 731.645833 874.0625 L 797.645833 874.0625 L 797.645833 775.8125 L 731.645833 775.8125 Z M 731.645833 874.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[95])}
                                            logo="matrix(0, -0.01, -0.01, 0, 617, 632)"
                                            bars="matrix(0, -0.36, 0.36, 0, 620, 649)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 797.645833 874.0625 L 863.645833 874.0625 L 863.645833 775.8125 L 797.645833 775.8125 Z M 797.645833 874.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[96])}
                                            logo="matrix(0, -0.01, -0.01, 0, 67, 707)"
                                            bars="matrix(0, -0.36, 0.36, 0, 70, 724)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 71.645833 972.3125 L 137.645833 972.3125 L 137.645833 874.0625 L 71.645833 874.0625 Z M 71.645833 972.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[97])}
                                            logo="matrix(0, -0.01, -0.01, 0, 117, 707)"
                                            bars="matrix(0, -0.36, 0.36, 0, 120, 724)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 137.645833 972.3125 L 203.645833 972.3125 L 203.645833 874.0625 L 137.645833 874.0625 Z M 137.645833 972.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[98])}
                                            logo="matrix(0, -0.01, -0.01, 0, 167, 707)"
                                            bars="matrix(0, -0.36, 0.36, 0, 170, 724)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 203.645833 972.3125 L 269.645833 972.3125 L 269.645833 874.0625 L 203.645833 874.0625 Z M 203.645833 972.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[99])}
                                            logo="matrix(0, -0.01, -0.01, 0, 217, 707)"
                                            bars="matrix(0, -0.36, 0.36, 0, 220, 724)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 269.645833 972.3125 L 335.645833 972.3125 L 335.645833 874.0625 L 269.645833 874.0625 Z M 269.645833 972.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[100])}
                                            logo="matrix(0, -0.01, -0.01, 0, 267, 707)"
                                            bars="matrix(0, -0.36, 0.36, 0, 270, 724)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 335.645833 972.3125 L 401.645833 972.3125 L 401.645833 874.0625 L 335.645833 874.0625 Z M 335.645833 972.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[101])}
                                            logo="matrix(0, -0.01, -0.01, 0, 317, 707)"
                                            bars="matrix(0, -0.36, 0.36, 0, 320, 724)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 401.645833 972.3125 L 467.645833 972.3125 L 467.645833 874.0625 L 401.645833 874.0625 Z M 401.645833 972.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[102])}
                                            logo="matrix(0, -0.01, -0.01, 0, 367, 707)"
                                            bars="matrix(0, -0.36, 0.36, 0, 370, 724)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 467.645833 972.3125 L 533.645833 972.3125 L 533.645833 874.0625 L 467.645833 874.0625 Z M 467.645833 972.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[103])}
                                            logo="matrix(0, -0.01, -0.01, 0, 417, 707)"
                                            bars="matrix(0, -0.36, 0.36, 0, 420, 724)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 533.645833 972.3125 L 599.645833 972.3125 L 599.645833 874.0625 L 533.645833 874.0625 Z M 533.645833 972.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[104])}
                                            logo="matrix(0, -0.01, -0.01, 0, 467, 707)"
                                            bars="matrix(0, -0.36, 0.36, 0, 470, 724)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 599.645833 972.3125 L 665.645833 972.3125 L 665.645833 874.0625 L 599.645833 874.0625 Z M 599.645833 972.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[105])}
                                            logo="matrix(0, -0.01, -0.01, 0, 517, 707)"
                                            bars="matrix(0, -0.36, 0.36, 0, 520, 724)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 665.645833 972.3125 L 731.645833 972.3125 L 731.645833 874.0625 L 665.645833 874.0625 Z M 665.645833 972.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[106])}
                                            logo="matrix(0, -0.01, -0.01, 0, 567, 707)"
                                            bars="matrix(0, -0.36, 0.36, 0, 570, 724)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 731.645833 972.3125 L 797.645833 972.3125 L 797.645833 874.0625 L 731.645833 874.0625 Z M 731.645833 972.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[107])}
                                            logo="matrix(0, -0.01, -0.01, 0, 617, 707)"
                                            bars="matrix(0, -0.36, 0.36, 0, 620, 724)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 797.645833 972.3125 L 863.645833 972.3125 L 863.645833 874.0625 L 797.645833 874.0625 Z M 797.645833 972.3125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[108])}
                                            logo="matrix(0, -0.01, -0.01, 0, 67, 780)"
                                            bars="matrix(0, -0.36, 0.36, 0, 70, 797)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 71.645833 1070.5625 L 137.645833 1070.5625 L 137.645833 972.3125 L 71.645833 972.3125 Z M 71.645833 1070.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[109])}
                                            logo="matrix(0, -0.01, -0.01, 0, 117, 780)"
                                            bars="matrix(0, -0.36, 0.36, 0, 120, 797)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 137.645833 1070.5625 L 203.645833 1070.5625 L 203.645833 972.3125 L 137.645833 972.3125 Z M 137.645833 1070.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[110])}
                                            logo="matrix(0, -0.01, -0.01, 0, 167, 780)"
                                            bars="matrix(0, -0.36, 0.36, 0, 170, 797)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 203.645833 1070.5625 L 269.645833 1070.5625 L 269.645833 972.3125 L 203.645833 972.3125 Z M 203.645833 1070.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[111])}
                                            logo="matrix(0, -0.01, -0.01, 0, 217, 780)"
                                            bars="matrix(0, -0.36, 0.36, 0, 220, 797)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 269.645833 1070.5625 L 335.645833 1070.5625 L 335.645833 972.3125 L 269.645833 972.3125 Z M 269.645833 1070.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[112])}
                                            logo="matrix(0, -0.01, -0.01, 0, 267, 780)"
                                            bars="matrix(0, -0.36, 0.36, 0, 270, 797)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 335.645833 1070.5625 L 401.645833 1070.5625 L 401.645833 972.3125 L 335.645833 972.3125 Z M 335.645833 1070.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[113])}
                                            logo="matrix(0, -0.01, -0.01, 0, 317, 780)"
                                            bars="matrix(0, -0.36, 0.36, 0, 320, 797)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 401.645833 1070.5625 L 467.645833 1070.5625 L 467.645833 972.3125 L 401.645833 972.3125 Z M 401.645833 1070.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[114])}
                                            logo="matrix(0, -0.01, -0.01, 0, 367, 780)"
                                            bars="matrix(0, -0.36, 0.36, 0, 370, 797)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 467.645833 1070.5625 L 533.645833 1070.5625 L 533.645833 972.3125 L 467.645833 972.3125 Z M 467.645833 1070.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[115])}
                                            logo="matrix(0, -0.01, -0.01, 0, 417, 780)"
                                            bars="matrix(0, -0.36, 0.36, 0, 420, 797)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 533.645833 1070.5625 L 599.645833 1070.5625 L 599.645833 972.3125 L 533.645833 972.3125 Z M 533.645833 1070.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[116])}
                                            logo="matrix(0, -0.01, -0.01, 0, 467, 780)"
                                            bars="matrix(0, -0.36, 0.36, 0, 470, 797)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 599.645833 1070.5625 L 665.645833 1070.5625 L 665.645833 972.3125 L 599.645833 972.3125 Z M 599.645833 1070.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[117])}
                                            logo="matrix(0, -0.01, -0.01, 0, 517, 780)"
                                            bars="matrix(0, -0.36, 0.36, 0, 520, 797)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 665.645833 1070.5625 L 731.645833 1070.5625 L 731.645833 972.3125 L 665.645833 972.3125 Z M 665.645833 1070.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[118])}
                                            logo="matrix(0, -0.01, -0.01, 0, 567, 780)"
                                            bars="matrix(0, -0.36, 0.36, 0, 570, 797)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 731.645833 1070.5625 L 797.645833 1070.5625 L 797.645833 972.3125 L 731.645833 972.3125 Z M 731.645833 1070.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[119])}
                                            logo="matrix(0, -0.01, -0.01, 0, 617, 780)"
                                            bars="matrix(0, -0.36, 0.36, 0, 620, 797)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 797.645833 1070.5625 L 863.645833 1070.5625 L 863.645833 972.3125 L 797.645833 972.3125 Z M 797.645833 1070.5625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[120])}
                                            logo="matrix(0, -0.01, -0.01, 0, 67, 853)"
                                            bars="matrix(0, -0.36, 0.36, 0, 70, 870)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 71.645833 1168.8125 L 137.645833 1168.8125 L 137.645833 1070.5625 L 71.645833 1070.5625 Z M 71.645833 1168.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[121])}
                                            logo="matrix(0, -0.01, -0.01, 0, 117, 853)"
                                            bars="matrix(0, -0.36, 0.36, 0, 120, 870)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 137.645833 1168.8125 L 203.645833 1168.8125 L 203.645833 1070.5625 L 137.645833 1070.5625 Z M 137.645833 1168.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[122])}
                                            logo="matrix(0, -0.01, -0.01, 0, 167, 853)"
                                            bars="matrix(0, -0.36, 0.36, 0, 170, 870)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 203.645833 1168.8125 L 269.645833 1168.8125 L 269.645833 1070.5625 L 203.645833 1070.5625 Z M 203.645833 1168.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[123])}
                                            logo="matrix(0, -0.01, -0.01, 0, 217, 853)"
                                            bars="matrix(0, -0.36, 0.36, 0, 220, 870)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 269.645833 1168.8125 L 335.645833 1168.8125 L 335.645833 1070.5625 L 269.645833 1070.5625 Z M 269.645833 1168.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[124])}
                                            logo="matrix(0, -0.01, -0.01, 0, 267, 853)"
                                            bars="matrix(0, -0.36, 0.36, 0, 270, 870)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 335.645833 1168.8125 L 401.645833 1168.8125 L 401.645833 1070.5625 L 335.645833 1070.5625 Z M 335.645833 1168.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[125])}
                                            logo="matrix(0, -0.01, -0.01, 0, 317, 853)"
                                            bars="matrix(0, -0.36, 0.36, 0, 320, 870)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 401.645833 1168.8125 L 467.645833 1168.8125 L 467.645833 1070.5625 L 401.645833 1070.5625 Z M 401.645833 1168.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[126])}
                                            logo="matrix(0, -0.01, -0.01, 0, 367, 853)"
                                            bars="matrix(0, -0.36, 0.36, 0, 370, 870)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 467.645833 1168.8125 L 533.645833 1168.8125 L 533.645833 1070.5625 L 467.645833 1070.5625 Z M 467.645833 1168.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[127])}
                                            logo="matrix(0, -0.01, -0.01, 0, 417, 853)"
                                            bars="matrix(0, -0.36, 0.36, 0, 420, 870)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 533.645833 1168.8125 L 599.645833 1168.8125 L 599.645833 1070.5625 L 533.645833 1070.5625 Z M 533.645833 1168.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[128])}
                                            logo="matrix(0, -0.01, -0.01, 0, 467, 853)"
                                            bars="matrix(0, -0.36, 0.36, 0, 470, 870)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 599.645833 1168.8125 L 665.645833 1168.8125 L 665.645833 1070.5625 L 599.645833 1070.5625 Z M 599.645833 1168.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[129])}
                                            logo="matrix(0, -0.01, -0.01, 0, 517, 853)"
                                            bars="matrix(0, -0.36, 0.36, 0, 520, 870)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 665.645833 1168.8125 L 731.645833 1168.8125 L 731.645833 1070.5625 L 665.645833 1070.5625 Z M 665.645833 1168.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[130])}
                                            logo="matrix(0, -0.01, -0.01, 0, 567, 853)"
                                            bars="matrix(0, -0.36, 0.36, 0, 570, 870)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 731.645833 1168.8125 L 797.645833 1168.8125 L 797.645833 1070.5625 L 731.645833 1070.5625 Z M 731.645833 1168.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[131])}
                                            logo="matrix(0, -0.01, -0.01, 0, 617, 853)"
                                            bars="matrix(0, -0.36, 0.36, 0, 620, 870)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 797.645833 1168.8125 L 863.645833 1168.8125 L 863.645833 1070.5625 L 797.645833 1070.5625 Z M 797.645833 1168.8125 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[132])}
                                            logo="matrix(0, -0.01, -0.01, 0, 67, 926)"
                                            bars="matrix(0, -0.36, 0.36, 0, 70, 943)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 71.645833 1267.0625 L 137.645833 1267.0625 L 137.645833 1168.8125 L 71.645833 1168.8125 Z M 71.645833 1267.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[133])}
                                            logo="matrix(0, -0.01, -0.01, 0, 117, 926)"
                                            bars="matrix(0, -0.36, 0.36, 0, 120, 943)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 137.645833 1267.0625 L 203.645833 1267.0625 L 203.645833 1168.8125 L 137.645833 1168.8125 Z M 137.645833 1267.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[134])}
                                            logo="matrix(0, -0.01, -0.01, 0, 167, 926)"
                                            bars="matrix(0, -0.36, 0.36, 0, 170, 943)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 203.645833 1267.0625 L 269.645833 1267.0625 L 269.645833 1168.8125 L 203.645833 1168.8125 Z M 203.645833 1267.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[135])}
                                            logo="matrix(0, -0.01, -0.01, 0, 217, 926)"
                                            bars="matrix(0, -0.36, 0.36, 0, 220, 943)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 269.645833 1267.0625 L 335.645833 1267.0625 L 335.645833 1168.8125 L 269.645833 1168.8125 Z M 269.645833 1267.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[136])}
                                            logo="matrix(0, -0.01, -0.01, 0, 267, 926)"
                                            bars="matrix(0, -0.36, 0.36, 0, 270, 943)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 335.645833 1267.0625 L 401.645833 1267.0625 L 401.645833 1168.8125 L 335.645833 1168.8125 Z M 335.645833 1267.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[137])}
                                            logo="matrix(0, -0.01, -0.01, 0, 317, 926)"
                                            bars="matrix(0, -0.36, 0.36, 0, 320, 943)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 401.645833 1267.0625 L 467.645833 1267.0625 L 467.645833 1168.8125 L 401.645833 1168.8125 Z M 401.645833 1267.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[138])}
                                            logo="matrix(0, -0.01, -0.01, 0, 367, 926)"
                                            bars="matrix(0, -0.36, 0.36, 0, 370, 943)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 467.645833 1267.0625 L 533.645833 1267.0625 L 533.645833 1168.8125 L 467.645833 1168.8125 Z M 467.645833 1267.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[139])}
                                            logo="matrix(0, -0.01, -0.01, 0, 417, 926)"
                                            bars="matrix(0, -0.36, 0.36, 0, 420, 943)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 533.645833 1267.0625 L 599.645833 1267.0625 L 599.645833 1168.8125 L 533.645833 1168.8125 Z M 533.645833 1267.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[140])}
                                            logo="matrix(0, -0.01, -0.01, 0, 467, 926)"
                                            bars="matrix(0, -0.36, 0.36, 0, 470, 943)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 599.645833 1267.0625 L 665.645833 1267.0625 L 665.645833 1168.8125 L 599.645833 1168.8125 Z M 599.645833 1267.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[141])}
                                            logo="matrix(0, -0.01, -0.01, 0, 517, 926)"
                                            bars="matrix(0, -0.36, 0.36, 0, 520, 943)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 665.645833 1267.0625 L 731.645833 1267.0625 L 731.645833 1168.8125 L 665.645833 1168.8125 Z M 665.645833 1267.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[142])}
                                            logo="matrix(0, -0.01, -0.01, 0, 567, 926)"
                                            bars="matrix(0, -0.36, 0.36, 0, 570, 943)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 731.645833 1267.0625 L 797.645833 1267.0625 L 797.645833 1168.8125 L 731.645833 1168.8125 Z M 731.645833 1267.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                        <GroupCode code={funciones.formatCeros(item[143])}
                                            logo="matrix(0, -0.01, -0.01, 0, 617, 926)"
                                            bars="matrix(0, -0.36, 0.36, 0, 620, 943)">
                                            <path
                                                fill="none" strokeWidth="0.5669" stroke="none"
                                                d="M 797.645833 1267.0625 L 863.645833 1267.0625 L 863.645833 1168.8125 L 797.645833 1168.8125 Z M 797.645833 1267.0625 "
                                                transform="matrix(0.75,0,0,0.75,0,0)" />
                                        </GroupCode>
                                    </g>
                                </svg>
                            )
                        })}

                    </div>

                    <div className="options_output">
                        <button className="btn btn-common btn_icon" onClick={() => window.print()}> <Iconos.PrinterOutline /> Imprimir Códigos</button>
                        {generating ?
                            <button className="btn btn-common btn_icon"> <Iconos.FilePdf /> Descargando <span id="contador">{cantidad}</span> páginas...</button>
                            :
                            <button className="btn btn-common btn_icon" onClick={() => setGenerating(true)}> <Iconos.FilePdf /> Descargar Códigos</button>
                        }
                    </div>
                </div>}

                {modalView}
            </div>
        </RequirePermissions>
    )
}

export default withRouter(CodeGenerator);